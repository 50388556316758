const billDeskSDK = async (order, auto_renew) => {
  
    if(auto_renew == true) {


        var flow_config = {
            merchantId:order.PGResponse.Response.mercid,
            bdOrderId: order.PGResponse.Response.bdorderid, // get from orderCreate response
            authToken: order.PGResponse.Response.links[1].headers.authorization, // get from orderCreate response
            childWindow: false,
            returnUrl: order.PGResponse.SubscriptionParam.RetrunUrl,
            crossButtonHandling: 'Y',
            retryCount: 3,
        };
        var config = {
            flowConfig: flow_config,
            flowType: "payments"
        };
      
    }
else {

    var flow_config = {
       merchantId:order.PGResponse.Response.mercid,
       bdOrderId: order.PGResponse.Response.bdorderid, // get from orderCreate response
       authToken: order.PGResponse.Response.links[1].headers.authorization, // get from orderCreate response
       childWindow: false,
       returnUrl: order.PGResponse.SubscriptionParam.RetrunUrl,
       crossButtonHandling: 'Y',
       retryCount: 3,
   };
   var config = {
       flowConfig: flow_config,
       flowType: "payments"
   };
  
}
   window.loadBillDeskSdk(config);

}

export {billDeskSDK}