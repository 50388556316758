import axios from "axios";
import { INITIATE_MID, PAYTM_URL, RETURN_URL, SUBSCRIPTION_MID } from "./Variables";
// const ENC_API_URL = "http://15.207.74.238:8080";
const ENC_API_URL = "https://otp.watcho.com:8080";
// const WATCHO_API_URL = "https://hrms.llv.edu.vn/api";
// const WATCHO_API_URL = "http://15.207.74.238/api";
const WATCHO_API_URL = "https://otp.watcho.com/api";
const encryptData = async (key_data) => {


  let config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization, X-Request-With',
      'Access-Control-Allow-Credentials': 'false'
    }
  }

  let data = await axios
    .post(ENC_API_URL + '/encrypt', {
      'data': JSON.stringify(key_data)
    }, config)

    .then(function (response) {

      return response.data.data;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}


const deryptData = async (key_data) => {


  let config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  }

  let data = await axios
    .post(ENC_API_URL + '/decrypt', {
      'data': key_data
    },
      config)
    .then(function (response) {
      return JSON.parse(response.data.data);
    })
    .catch(function (error) {
      return JSON.parse(error);
    });
 
  return data;
}

const getPlanList = async (params) => {

  let data = await axios
    .post(WATCHO_API_URL + '/getAvilableSubscriptionPlanDetails', {
      "data": params
    })
    .then(function (response) {
      return deryptData(response.data)
    })
    .catch(function (error) {
      return error
    });
  return data;
}

const applyCoupon = async (params) => {
  let data = await axios
    .post('/api/WatchoSubscription/VaildPromoCode ', {
      "Inputparam": params
    })
    .then(function (response) {

      return deryptData(response.data)
    })
    .catch(function (error) {
      return error
    });
  return data;
}

const sendOtpRequest = async (params, token) => {

  let config = {
    headers: {
      'Authorization': token,
    }
  }
  let data = await axios
    .post(WATCHO_API_URL + '/generateOTP', {

      // .post('/api/WatchoSubscription/GenerateOTPForLogin',{ 
      "data": params,
      "token": token
    }, config)
    .then(function (response) {
    
      return deryptData(response.data)
    })
    .catch(function (error) {
     
      return error
    });
  return data;
}


const validateOtp = async (params, token) => {
  let config = {
    headers: {
      'Authorization': token,
    }
  }
 
  let data = await axios
    .post(WATCHO_API_URL + '/validateOTPForLogin', {
      "data": params,
      "token": token
    }, config)

    .then(function (response) {
     
      return deryptData(response.data)
    })
    .catch(function (error) {

      return error
    });
  return data;
}





const VaildPromoCode = async (params, token) => {
  let config = {
    headers: {
      'Authorization': token,
    }
  }

  let data = await axios
    .post(WATCHO_API_URL + '/vaildPromoCode', {
      "data": params,
      "token": token
    }, config)
    .then(function (response) {
      return deryptData(response.data)
    })
    .catch(function (error) {
      return error;

    });
  return data;
}


const getActiveSubcriptionPlan = async (params, token) => {
  let config = {
    headers: {
      'Authorization': token,
    }
  }

  let data = await axios
    .post(WATCHO_API_URL + '/getActiveSubcriptionPlan', {
      "data": params,
      "token": token
    }, config)
    .then(function (response) {
      return deryptData(response.data)
    })
    .catch(function (error) {
      return error;

    });
  return data;
}



const generateTokenAPI = async (params) => {

  let data = await axios
    .post(WATCHO_API_URL + '/generateToken', {
      "data": params
    })
    .then(function (response) {
   
      return deryptData(response.data)
    })
    .catch(function (error) {
     
      return error;

    });
  return data;
}



const generateSubscriptionRequestAPI = async (params, token) => {
  let config = {
    headers: {
      'Authorization': token,
    }
  }

  let data = await axios
    .post(WATCHO_API_URL + '/generateSubscriptionRequest ', {
      "data": params,
      "token": token
    }, config)
    .then(function (response) {

      return deryptData(response.data)
    })
    .catch(function (error) {
     
      return error;

    });
  return data;
}



const generateSubscription = async (order, userId, auto_renew) => {

 
  if (auto_renew) {

    var body = {
      "requestType": "NATIVE_SUBSCRIPTION",
      "mid": SUBSCRIPTION_MID,
      "websiteName": order.PGResponse.SubscriptionParam.WebsiteName,
      "orderId": order.OrderID,
      "subscriptionAmountType": order.PGResponse.SubscriptionParam.AmountType,
      "subscriptionFrequency": order.PGResponse.SubscriptionParam.Frequency,
      "subscriptionFrequencyUnit": order.PGResponse.SubscriptionParam.FrequencyUnit,
      "subscriptionStartDate": order.PGResponse.SubscriptionParam.StartDate,
      "subscriptionGraceDays": order.PGResponse.SubscriptionParam.GraceDays,
      "subscriptionExpiryDate": order.PGResponse.SubscriptionParam.ExpiryDate,
      "subscriptionEnableRetry": order.PGResponse.SubscriptionParam.EnableRetry,

      "txnAmount": {
        "value": (order.ActualPayableAmount).toString(),
        "currency": 'INR'
      },
      "userInfo": {
        "custId": userId.toString()
      },
      "callbackUrl": `${RETURN_URL}?ORDER_ID=${order.OrderID}`,
      "renewalAmount":order.PGResponse.SubscriptionParam.RenewalAmount,
      "subscriptionMaxAmount":order.PGResponse.SubscriptionParam.MaxAmount
    };
    var url = `${PAYTM_URL}/subscription/create?mid=${SUBSCRIPTION_MID}&orderId=${order.OrderID}`;

  }
  else {

    body = {
      "requestType": "Payment",
      "mid": INITIATE_MID,
      "websiteName": order.PGResponse.SubscriptionParam.WebsiteName,
      "orderId": order.OrderID,
      "txnAmount": {
        "value": (order.ActualPayableAmount).toString(),
        "currency": 'INR'
      },
      "userInfo": {
        "custId": userId.toString()
      },
      "callbackUrl": `${RETURN_URL}?ORDER_ID=${order.OrderID}`,
      // "callbackUrl": `https://xojyysusvd.execute-api.ap-south-1.amazonaws.com/uat/v2/svod?ORDER_ID=${order.OrderID}`
    }
  var url = `${PAYTM_URL}/theia/api/v1/initiateTransaction?mid=${INITIATE_MID}&orderId=${order.OrderID}`;

  }
  var head = {
    "signature": order.PGResponse.Response
  };

  let data = await axios
    .post(url,
      { body: body, head: head })

    .then(function (response) {
     
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
  return data;
}


const transactionAPI = async (params, token) => {

  let config = {
    headers: {
      'Authorization': token,
    }
  }

  let data = await axios
    .post(WATCHO_API_URL + '/transactionAcknowledgment ', {
      "data": params,
      "token": token
    }, config)
    .then(function (response) {
     
      return deryptData(response.data)
    })
    .catch(function (error) {
      return error;

    });
  return data;
}

export { deryptData, getPlanList, encryptData, sendOtpRequest, validateOtp, VaildPromoCode, generateTokenAPI, generateSubscriptionRequestAPI, getActiveSubcriptionPlan, generateSubscription, transactionAPI }
