import React, { Fragment, useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './term-use.css'
import { Link, useNavigate } from 'react-router-dom'
function TermUse() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <Fragment>
      <Header />
      <div className='policy-wrapper'>
        <div className='policy-cardWrapper' >
          <h1 className="title"><a  onClick={() => navigate(-1)} className='back-btn'><i className="fa fa-arrow-left"></i></a> Terms Of Use</h1>
          <div className="html-terms template">
           
            <div className="temp_inner">
              <div className="mt20">
                <p>This Terms of Use (“Agreement”) shall govern your use of Watcho services (“Services”). The Services includes the subscription and access to the content available on Watcho Platform i.e. the website <a href="mailto:hello@watcho.com">hello@watcho.com</a> ("Website") and/or Watcho Mobile Application (“Application”) as well as on the third party Over The Top (OTT) applications/websites (“Third Party OTT Applications”). This Agreement is a legal and binding agreement between you, the user ("User"/ "You") and Dish Infra Services Private Limited (“Dish Infra”) and its subsidiaries or affiliated companies (collectively, "Dish Infra" or "we", "us" and "our") concerning your access and use of Services subscribed and accessed through Application/Website. By visiting or registering this Website /Application, operated and controlled by Dish Infra, and/or by submitting/uploading content to this Website/Application (wherever applicable), and/or subscribing to Watcho and/or the subscription packs containing the Third Party OTT Applications, your consent is implied to the terms and conditions mentioned herein and also the terms and conditions and privacy policy as mentioned with respect to your accessibility and use of Third Party OTT Application(s):</p>
                <div className="table-scroll">
                  <table className='table'>
                    <tbody><tr>
                      <th>Third Party OTT Application	</th>
                      <th>Terms and Conditions</th>
                      <th>Privacy Policy</th>
                    </tr>
                      <tr>
                        <td>Disney Hotstar</td>
                        <td> <a href="https://www.hotstar.com/in/terms-of-use" target="_blank">https://www.hotstar.com/in/terms-of-use</a></td>
                        <td> <a href="https://www.hotstar.com/privacy-policy" target="_blank">https://www.hotstar.com/privacy-policy</a></td>
                      </tr>
                      <tr>
                        <td>Zee5</td>
                        <td> <a href="https://www.zee5.com/termsofuse" target="_blank">	https://www.zee5.com/termsofuse</a></td>
                        <td> <a href="https://www.zee5.com/privacypolicy" target="_blank">	https://www.zee5.com/privacypolicy</a></td>
                      </tr>
                      <tr>
                        <td>Sonyliv</td>
                        <td> <a href="https://www.sonyliv.com/terms-of-use" target="_blank">		https://www.sonyliv.com/terms-of-use</a></td>
                        <td> <a href="https://www.sonyliv.com/privacy-policy" target="_blank">		https://www.sonyliv.com/privacy-policy</a></td>
                      </tr>
                      <tr>
                        <td>Hoichoi</td>
                        <td> <a href="https://www.hoichoi.tv/tos" target="_blank">		https://www.hoichoi.tv/tos</a></td>
                        <td> <a href="https://www.hoichoi.tv/privacy-policy" target="_blank">			https://www.hoichoi.tv/privacy-policy</a></td>
                      </tr>
                      <tr>
                        <td>Lionsgate</td>
                        <td> <a href="https://lionsgateplay.com/termsandconditions" target="_blank">	https://lionsgateplay.com/termsandconditions</a></td>
                        <td> <a href="https://lionsgateplay.com/privacypolic" target="_blank">https://lionsgateplay.com/privacypolicy</a></td>
                      </tr>
                      <tr>
                        <td>Hungama</td>
                        <td> <a href="https://www.hungama.com/conditions" target="_blank">	https://www.hungama.com/conditions/</a></td>
                        <td> <a href="https://www.hungama.com/privacy-policy/" target="_blank">	https://www.hungama.com/privacy-policy/</a></td>
                      </tr>
                      <tr>
                        <td>Klikk</td>
                        <td> <a href="http://klikk.tv/terms.html" target="_blank">	http://klikk.tv/terms.html</a></td>
                        <td> <a href="http://klikk.tv/privacy-policy.html" target="_blank">	http://klikk.tv/privacy-policy.html</a></td>
                      </tr>
                      <tr>
                        <td>Epic On</td>
                        <td> <a href="https://www.epicon.in/privacy-policy" target="_blank">	https://www.epicon.in/privacy-policy</a></td>
                        <td> <a href="http://klikk.tv/privacy-policy.html" target="_blank">	http://klikk.tv/privacy-policy.html</a></td>
                      </tr>
                      <tr>
                        <td>Oho Gujarati</td>
                        <td> <a href="https://www.ohogujarati.com/privacy-policy" target="_blank">	https://www.ohogujarati.com/privacy-policy</a></td>
                        <td> <a href="https://www.ohogujarati.com/privacy-policy" target="_blank">	https://www.ohogujarati.com/privacy-policy</a></td>
                      </tr>
                      <tr>
                        <td>Chaupal</td>
                        <td> <a href="https://about.chaupal.tv/terms.html" target="_blank">	https://about.chaupal.tv/terms.html</a></td>
                        <td> <a href="https://about.chaupal.tv/privacy.html" target="_blank">	https://about.chaupal.tv/privacy.html</a></td>
                      </tr>
                    </tbody></table>
                </div>
                <div className="mt10">
                  <p>These Third- Party OTT Applications and their services are neither owned nor controlled by Dish Infra. Further, Dish Infra has no control over and assumes no responsibility for the Third Party OTT Applications, their services, content, terms and conditions, privacy policies.
                  </p>

                  <h3 className="list_item" id="account"> Account and Membership</h3>
                  <p>In order to use premium features of the Service, You will have to create an Account using your email address and certain personal information. You must provide correct and complete information while making an account. You are solely responsible for the activity that occurs on your account, and must keep your account password protected to prevent unauthorized use of account.</p>
                  <p>You agree to pay the requisite charges for use of the Services in accordance with the rates published by Dish Infra on the Application/ Website, which may be modified from time to time.</p>
                  <p>As a matter of abundant caution, we advise that the account password shall not be disclosed to the third parties and you should update his/her account password at regular intervals.</p>
                  <p>In case of any breach of security or any unauthorized activity of the account, You shall immediately contact and notify us at <a href="mailto:hello@watcho.com">hello@watcho.com</a> .</p>
                  <p>In no event, Dish Infra will be liable for the losses caused by any unauthorized use of your account.</p>
                  <h3 className="list_item">License to Access the Service</h3>
                  <p> Dish Infra hereby grants its Users non-exclusive, revocable, non-transferable, non-assignable licenses to use its Services through the Website/ Application as per the terms of this Agreement.</p>
                  <p>You are permitted to view and use the Services for your personal non-commercial use only. You are not permitted to copy, distribute or modify any part of the content available on the Services. No part of the Services shall be reproduced or transmitted to or stored in any other website/ application, nor any of its pages or part thereof be disseminated in any electronic or non-electronic form, nor including in any public or private electronic retrieval system or service without prior written permission of Dish Infra.</p>
                  <p>The User must access the Services by redeeming coupons from an authorised source specifically designated for the purpose of accessing the Services. If the User avails or redeems any coupon procured from any source not authorised by Dish Infra or from any Local Cable Operators (“LCO”) other than the LCOs of Siti Networks Limited, then Dish Infra shall have the right to immediately cancel the usage of your Services without any notice and without any liability of any kind on Dish Infra including any refund, if any.</p>
                  <p>If you are a Corporate and have procured coupons for access of the Services from any source other than authorised sources of Dish Infra/ Siti Networks or any authorised distributor of Dish Infra/ Siti Networks, then such coupons shall be null and void and Dish Infra shall have the right to cancel your arrangement with Dish Infra/ Siti Networks as the case may be.</p>
                  <h3 className="list_item">Content Supplied by You</h3>
                  <p> Wherever applicable Content uploaded by an User shall be governed by the following:</p>
                  <div className="lists">
                    <ul className=" ml10">
                      <li>For purpose of this terms of use, "Content" means and includes the graphics and visuals, audio, photos, music, videos and all other materials User uploads or submits on the Website / Application.</li>
                      <li>Dish Infra do not claim and hold ownership of the Content on our platform. The Content on our platform is solely done at the discretion of the User, without any undue influence, duress and at free will and such user who has uploaded the Content shall be fully responsible for uploading such Content and shall bear the legal consequences at its own costs and consequences, and Dish Infra shall not be responsible for such Content, in any manner whatsoever.</li>
                      <li>If User wants to upload any Content or request removal of any Content from the Website/Application, they can write an e-mail to <a href="mailto:hello@watcho.com">hello@watcho.com</a>. For removal of Content the User must inform the whereabouts of the Content and reason of removal from the Website/Application. However, the Content will continue to appear if it has been shared and circulated on any other platform. Dish Infra may delete or remove Your Content (without any notice) from the Website/ Application in its sole discretion.</li>
                      <li>When User uploads/submits, shares any of their Content on our platform, User hereby grants to us a non-exclusive, royalty-free, transferable, sub-licensable, use, distribute, modify, display his/her Content (consistent with your privacy and application settings).</li>
                      <li>User represents, warrants, and agree that no Content posted or otherwise shared by him/her, violates or infringes upon the rights of any third party, including copyright, trademark, privacy, or any other personal or proprietary rights. User will not create, upload and circulate any Content under on the Website/Application which is unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material that would violate any law or hurts social, religious and political views or opinion of an individual, community or people at large. Dish Infra in any scenario, shall not be held liable for the Content(s) created, uploaded and circulated through this section of Website/Application/social media and sole liability and responsibility of such Content lies with the User.</li>
                      <li>When User uploads/submits, shares any of their Content on our platform, he/she shall not host, display, upload, modify, publish, transmit, store, update or share any information that</li>
                      <div className="lists ml10 mt10">
                        <p>i. Belongs to another person and to which the user does not have any right;</p>
                        <p>ii. Is defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;</p>
                        <p>iii. Is harmful to child;</p>
                        <p>iv. Infringes any patent, trademark, copyright or other proprietary rights;</p>
                        <p>v. Violates any law for the time being in force;</p>
                        <p>vi. Deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;</p>
                        <p>vii. Impersonates another person;</p>
                        <p>ix. Contains software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;</p>
                        <p>x. Is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person;</p>
                      </div>
                    </ul>

                  </div>




                </div>
              </div>
              <h3 className="list_item">Compliance of terms of Use/User Agreement by the User</h3>
              <p>User understands that in case of non-compliance with the Agreement, Dish Infra shall have the right to terminate the access or usage rights of the users and/or terminate his account immediately or remove non-compliant information or both, as the case may be.</p>

              <h3 className="list_item"> Copyright &amp; Trademark</h3>
              <div className=" mt10">
                <p>The Services contains intellectual property rights in form of content and other material presented/posted/displayed (including but not limited to text, audio, video, graphical visuals), trademarks and logos (including trade mark "Watcho") of Dish Infra, its parent, affiliates and associates and also of the Third Party OTT Applications and are protected under the applicable Indian laws. Any infringement of the intellectual property rights will be considered illegal and shall be vigorously defended and pursued to the fullest extent permitted by law.</p>
                <p>All copyright, trademarks and all other intellectual property rights in all material or content supplied as part of the Website/ Application shall remain at all times vested in Dish Infra or its licensors or third party vendors. User will not assist or facilitate any third party to copy, reproduce, transmit, distribute, frame, commercially exploit or create derivative works of such material or content.</p>
                <p>If any person or entity believes that their content has been copied which is infringing their copyright, please provide the following information:</p>
                <div className="lists ml15">
                  <p>i. Details and full identification of the copyrighted work claimed to have been infringed.</p>
                  <p>ii. Location and identity of the copyrighted on the Website/Application.</p>
                  <p>iii. Sufficient information where you can be Contacted or reached including your email address, postal address and contact no.</p>
                  <p>iv. A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
                  <p>v. Notice of such claims of copyright infringement shall be e-mailed to <a href="mailto:hello@watcho.com">hello@watcho.com</a> .</p>
                </div>
              </div>
              <h4 className="list_item"> Changes</h4>
              <p>Dish Infra reserve the right to add or change/modify the Website/Application or the Agreement at any point of time. Any changes relating to the Agreement will be posted on <a href="www.watcho.com/terms_of_use"> www.watcho.com/terms_of_use</a> and it is responsibility of the User to refer to the Agreement on accessing the Website/Application. Changes could be made by Dish Infra after the first posting to the Website/Application at any time, and such modifications shall be effective immediately upon posting of the modified Terms of Use. You agree to review the Terms of Use periodically to be aware of such modifications and your continued access or use of the Website/Application shall be deemed your conclusive acceptance of the modified Term of Use.</p>
              <h4 className="list_item">  Limited Permission to Copy</h4>
              <p>You will not (whether directly or through the use of any software program) create a database in electronic or structured manual form by regularly or systematically downloading and storing all or any part of the pages from the Website/Application. No part of the Website/Application may be reproduced or transmitted to or stored in any other web site, nor may any of its pages or part thereof be disseminated in any electronic or non-electronic form, nor including in any public or private electronic retrieval system or service without prior written permission of Dish Infra.</p>
              <h4 className="list_item">    Disclaimer of Warranties and Liabilities</h4>
              <div className=" mt10">
                <p>The Services are provided "AS IS" ,"WITH ALL FAULTS" AND "AS AVAILABLE" and without warranties of any kind either express or implied. To the fullest extent permissible pursuant to law, Dish Infra disclaims all liability, express or implied, including but not limited to, implied warranties and conditions of merchantability and fitness for a particular purpose, workmanlike effort, title and non-infringement. Dish Infra does not warrant that the availability of or functions contained in the Services will be uninterrupted or error free or that defect will be corrected or are free from virus or other harmful components. Dish Infra does not warrant or make any representations regarding the use or result of the use of the Service in terms of correctness, reliability, accuracy or otherwise. You, and not Dish Infra, assume the entire risk as to the quality and performance of the Services.</p>
                <p>Except for Dish Infra identified information, products or services, other than the digital STB, all information, products and services offered through the site or on the Internet generally are offered by third parties that are not affiliated with Dish Infra.</p>
                <p>The Services may provide link of other website(s) maintained or controlled by third parties and Dish Infra does not control such third party website(s) and is not responsible for any transactions on such website(s).</p>
                <p>You understand further that certain content contains unedited materials some of which are sexually explicit or may be offensive to you. You access such materials at your risk. Dish Infra has no control over and accepts no responsibility whatsoever for such materials.</p>
                <p>Dish Infra and its parent, affiliates and associates shall not be liable, at any time for any loss and/or damage arising directly or indirectly (including, without limitation, special, incidental and/or consequential loss and/or damage) arising in contract, tort or otherwise from the use of or inability to use the Services or from any action taken (or refrained from being taken) as a result of using the Services or due to any unavailability of the Services or any part thereof or for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Services. No representations, warranties or guarantees whatsoever are made as to the accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation.</p>
                <p>Any hyperlinks from or to the Services for information purposes and are for your convenience only. Dish Infra accepts no liability for any loss or damage arising directly or indirectly (including consequential loss) from the accuracy or otherwise of materials or information contained on the pages of such Services or loss arising directly or indirectly from defects with Services. Dish Infra neither endorses in any way nor offers any judgment or warranty and accepts no responsibility or liability for the authenticity, availability of any of the goods or services or for any damage, loss or harm, direct or consequential or any violation of local or international laws that may be incurred by your visiting and transacting on these Services.</p>
                <p>Part of the Services will contain advertising and other material submitted to Dish Infra by third parties. Dish Infra accepts no responsibility for advertisements or promotions appearing on the Services. Advertisers are responsible for ensuring that material submitted for inclusion on the Services complies with applicable International and National law. Dish Infra will not be responsible for any advertiser's claim, error, omission or inaccuracy in advertising material, and reserves the right to omit, suspend or change the position of any advertising material submitted for insertion. You deal with such advertisers or promoters on your own accord and on their terms</p>

              </div>
              <h4 className="list_item">Indemnification.</h4>
              <p>You agree to indemnify, defend and hold Dish Infra, its affiliates, officers, directors, employees, agents, licensors, suppliers and any third party information providers to the Services harmless from and against all losses, expenses, damages and costs including but not limited to attorneys' fees, other expenses that arise directly or indirectly resulting out of or from your breach of the Agreement, your violation of the undertaking, representation and covenants as mentioned above and/or your activities in connection with the Service.</p>


              <h4 className="list_item">Third Party Rights</h4>
              <p>The provisions of Disclaimer of Warranties and Liabilities and Indemnification are for the benefit of Dish Infra and its officers, directors, employees, agents, licensors, suppliers, and any third party information providers to the Service. Each of these individuals or entities shall have the right to assert and enforce those provisions directly against You on their own behalf.</p>

              <h4 className="list_item">Severability</h4>
              <p>If any provision of the terms and condition shall be held unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of the remaining provisions.</p>

              <h4 className="list_item">Registration and Acceptance of Terms</h4>
              <p>In consideration of your use of service of the site, you agree to</p>

              <div className="lists ml15">
                <p>i. Comply with the Agreement.</p>
                <p>ii. Provide accurate, complete and true information about yourself as may be required on any registration form for the service. In order to create your User Account, and</p>
                <p>iii. Maintain and update, as applicable, your registration information with current and complete information. Users who violate these rules, or provide inaccurate, false or non-current registration information may, at Dish Infra's sole discretion, have their User account suspended or terminated and may be permanently banned from having any current or future service from the Services.</p>
              </div>
              <h4 className="list_item">Governing Law &amp; Jurisdiction      </h4>
              <p>This User Agreement shall be governed by the Laws of India. The Courts at Delhi only shall have exclusive Jurisdiction over any disputes arising under this Agreement. Notwithstanding the foregoing, User agrees that Dish Infra has the right to bring any proceedings before any court/forum of competent jurisdiction and User conclusively submit to the jurisdiction of such courts or forum.</p>
              <p>Please note that in case of non-compliance of the Agreement we have a right to terminate the access or your usage rights of the Services immediately or remove non-compliant information or both as the case may be.</p>
              <p>Last Updated On: September 30, 2022</p>
            </div>
          </div>


        </div>
      </div>
      <Footer />

    </Fragment>
  )
}

export default TermUse