import { dateFormatting, getUserType } from "./Functions";
import { GTM_ID } from "./Variables";

const pushGTMTAG = async (orderState, gta_event, user = null, data = null) => {
  var dataLayer = {
    'user_type': getUserType(),
    'plan_duration': orderState.SubscriptionPlanDurationName,
    'plan_amount': orderState.SubscriptionPriceWithTax,
    'plan_name': orderState.SubscriptionPlanName,
    'plan_id': orderState.SubscriptionPlanID,
  };
  if (gta_event == 'signin_completed') {
    dataLayer['user_type'] = getUserType(user.CustomerCategory);
    dataLayer['ott_sms_id'] = user.OTTSMSID;
  }
  else if (gta_event == 'apply_coupon') {
    dataLayer['user_type'] = getUserType(user.CustomerCategory);
    dataLayer['ott_sms_id'] = user.OTTSMSID;
    dataLayer['coupon_code'] = data.trim();
  }
  else if (gta_event == 'continue_to_pay') {
    dataLayer['user_type'] = getUserType(user.CustomerCategory);
    dataLayer['ott_sms_id'] = user.OTTSMSID;
    dataLayer['payment_mode'] = data.PGMode
  }
  const tagManagerArgs = {
    gtmId: GTM_ID,
    event: gta_event,
    gtm: dataLayer
  }
  window.dataLayer.push(tagManagerArgs);
} 



const pushGTMTAGThankYouPage = async (gta_event, data, TransactionNo = null, expiry_date = null) => {
  window.dataLayer = window.dataLayer || [];
  var dataLayer = {
    'plan_duration': data.plan.SubscriptionPlanDurationName,
    'plan_amount': data.plan.SubscriptionPriceWithTax,
    'plan_name': data.plan.SubscriptionPlanName,
    'plan_id': data.plan.SubscriptionPlanID,
   
   
  };
  if (gta_event == 'payment_success') {
    dataLayer['user_type'] = getUserType(data.CustomerCategory);
    dataLayer['ott_sms_id'] = data.OTTSMSID;
    dataLayer['ar_status'] = true;
    dataLayer['start_date'] = dateFormatting(data.order.PGResponse.SubscriptionParam.StartDate);
    dataLayer['end_date'] = expiry_date;
    dataLayer['coupon_code'] = data.coupon_code;
    dataLayer['transaction_id'] = TransactionNo;
    dataLayer['order_id'] = data.order.OrderID;
    dataLayer['payment_mode'] = data.order.PGMode;



  }
  else if (gta_event == 'payment_success_annual') {
    dataLayer['user_type'] = getUserType(data.CustomerCategory);
    dataLayer['ott_sms_id'] = data.OTTSMSID;
    dataLayer['ar_status'] = true;
    dataLayer['start_date'] = dateFormatting(data.order.PGResponse.SubscriptionParam.StartDate);
    dataLayer['end_date'] = expiry_date;
    dataLayer['coupon_code'] = data.coupon_code;
    dataLayer['transaction_id'] = TransactionNo;
    dataLayer['order_id'] = data.order.OrderID;
    dataLayer['payment_mode'] = data.order.PGMode;
    dataLayer['channel_partner'] = 'Channel Partner';
    dataLayer['platform'] = 'Web';

  }
  else if (gta_event == 'payment_failed') {
    dataLayer['user_type'] = getUserType(data.CustomerCategory);
    dataLayer['ott_sms_id'] = data.OTTSMSID;
    dataLayer['coupon_code'] = data.coupon_code;
    dataLayer['transaction_id'] = TransactionNo;
    dataLayer['order_id'] = data.order.OrderID;
    dataLayer['payment_mode'] = data.order.PGMode;

  }
  else if (gta_event == 'continue_to_watch') {
    dataLayer['user_type'] = getUserType(data.CustomerCategory);
    dataLayer['ott_sms_id'] =  data.OTTSMSID;
    dataLayer['ar_status'] = true;
    dataLayer['start_date'] = dateFormatting(data.order.PGResponse.SubscriptionParam.StartDate);
    dataLayer['end_date'] = expiry_date;
  }
 
  const tagManagerArgs = {
    gtmId: GTM_ID,
    event: gta_event,
    gtm: dataLayer
  }
  window.dataLayer.push(tagManagerArgs);
}
export { pushGTMTAG , pushGTMTAGThankYouPage}